<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('LABEL:AdminDescriptions') }}<span v-if="descriptionsCounter > 0">({{ descriptionsCounter }})</span>
    </PageTitle>
    <Loader v-if="descriptionDataLoading" class="h-050screen"/>
    <t-card v-else class="w-page-width">
      <template v-if="descriptionData">
        <div class="flex flex-col gap-3">
          <!-- Picture + Username-->
          <div class="flex flex-row w-full gap-6">
            <div class="w-300">
              Placeholder for ID picture
            </div>
            <div
                @click="showProfileModal(descriptionData.username)"
                class="w-676 font-semibold hover:text-redAccentLight hover:opacity-70 cursor-pointer"
            >
              {{ descriptionData.name }}
            </div>
          </div>
          <hr/>
          <div class="w-full flex flex-row gap-6">
            <!-- New description -->
            <div class="w-676 flex flex-col gap-3">
              <div class="font-semibold ">
                {{ trans('LABEL:NewDescription') }}
              </div>
              <div
                v-html="descriptionData.descriptionNew">
              </div>
            </div>
            <!-- Right column - Statusy -->
            <div class="w-300 flex flex-col gap-3">
              <div class="w-300 flex flex-row gap-6">
                <div class="flex flex-col gap-2">
                  <div class="flex" v-for="status in descriptionStatusesPositive" :key="status.slug">
                    <label class="flex flex-row items-center gap-1">
                      <t-radio :name="`description_status_id`" v-model="newDescriptionStatus" :value="status.id"/>
                      <span class="text-s cursor-pointer text-badgeSuccess">{{ trans(status.name) }}</span>
                    </label>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <div class="flex" v-for="status in descriptionStatusesNegative" :key="status.id">
                    <label class="flex flex-row items-center gap-1">
                      <t-radio :name="`description_status_id`" v-model="newDescriptionStatus" :value="status.id"/>
                      <span class="text-s cursor-pointer text-badgeError">{{ trans(status.name) }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="w-full flex flex-row gap-6">
            <div class="w-676 flex flex-col gap-3 group">
            </div>
            <div class="w-300">
              <Button
                  @clicked="updateStatusAction"
                  :loading="descriptionUpdating"
                  :disabled="editBtnBlocked"
              >
                {{ trans('BUTTON:SaveStatus') }}
              </Button>
            </div>
          </div>
          <!-- Old description-->
          <div class="w-676 flex flex-col gap-3 group">
            <div class="font-semibold text-grayed group-hover:text-black">
              {{ trans('LABEL:PreviousDescription') }}
            </div>
            <div class="text-sm text-grayed group-hover:text-black"
                 v-html="descriptionData.descriptionOld">
            </div>
          </div>
          <div class="w-300">
            <!-- Empty -->
          </div>
        </div>
      </template>
      <template v-else>
        {{ trans('LABEL:NoDescriptionsToAccept') }}
      </template>
    </t-card>

    <Modal
      variant="profileAdmin"
      hide-footer
      v-if="isShowProfileModal"
      :show-modal="isShowProfileModal"
      @clicked="isShowProfileModal = false"
    >
      <template #content>
        <ProfileGuest :username="profileUsername"/>
      </template>
    </Modal>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Loader from "@/views/Loader";
import api from "@/common/services/api";

export default {
  name: "AdminDescriptions",
  components: {
    AdminPageFrame,
    Button,
    Loader,
    Modal,
    PageTitle,
    ProfileGuest,
  },
  data() {
    return {
      newDescriptionStatus: [],
      isShowProfileModal: false,
	    channelName: 'description-approving.',
	    editBtnBlocked: false,
    }
  },
  watch: {
	  descriptionData: {
		  deep: true,
		  immediate: true,
		  handler(val) {
			  if (val) {
				  let presenceChannel = window.Echo.join(`${this.channelName}${val.id}`);

				  setTimeout(() => {
					  this.editBtnBlocked = true;
					  this.leaveChannel(val.id);

				  }, Number(this.configData.presenceChannelsTimeout));
			  }
		  }
	  },
  },
  computed: {
    ...mapGetters('admin', ['descriptionData','descriptionStatusesNegative', 'descriptionStatusesPositive','descriptionUpdating', 'descriptionsCounter', 'descriptionDataLoading']),
	  ...mapGetters('lang', ['configData']),
  },
  methods: {
    ...mapActions('admin', ['updateDescriptionStatus', 'fetchDescriptionData']),
		...mapMutations('admin', ['setDescriptionData']),
    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },

    updateStatusAction() {
      this.updateDescriptionStatus({
        description_status_id: this.newDescriptionStatus,
        user_id: this.descriptionData.id
      });
    },
	  leaveChannel(channelId) {
		  window.Echo.leave(`${this.channelName}${channelId}`);
		  this.setDescriptionData({description: null});
	  },
  },
	beforeDestroy() {
			window.Echo.leave(`${this.channelName}${this.descriptionData?.id}`)
			this.setDescriptionData({description: null});
	},
	mounted() {
		this.fetchDescriptionData()
  }
}
</script>
